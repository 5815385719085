import React, { useState } from 'react';
import { bool, func, shape, string } from 'prop-types';
import classNames from 'classnames';
import { Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import { findOptionsForSelectFilter } from '../../util/search';
import { propTypes } from '../../util/types';
import config from '../../config';
import { Button, FieldRadioButton, Form, FieldTextInput, FieldCheckbox } from '../../components';

import css from './EditListingAgeForm.module.css';
import { min } from 'lodash';
import {
  ACTIVITYMODE,
  AGE,
  LOCATION,
} from '../../components/EditListingWizard/EditListingWizardTab';
import ProgressBar from '../../components/ProgressBar/ProgressBar';
import { GrCheckbox, GrCheckboxSelected } from 'react-icons/gr';

const EditListingAgeFormComponent = props => (
  <FinalForm
    {...props}
    mutators={{ ...arrayMutators }}
    render={formRenderProps => {
      const {
        disabled,
        ready,
        rootClassName,
        className,
        name,
        intl,
        handleSubmit,
        pristine,
        saveActionMsg,
        updated,
        values,
        updateInProgress,
        fetchErrors,
        tabProgress,
        form,
        history,
        initialValues,
        publicData,
        backButton,
        saveAndExit,
        backbtnLoader,
      } = formRenderProps;
      const tabPercent = tabProgress(AGE);
      const classes = classNames(rootClassName || css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = disabled || submitInProgress;
      const [maxAgeError, setMaxAgeError] = useState(false);
      const [invalidAgeError, setInvalidAgeError] = useState(false);
      const [fixedMinAgeError, setFixedMinAgeError] = useState(false);
      const [minAgeError, setMinAgeError] = useState(false);
      const [minAge, setMinAge] = useState(!initialValues?.minAge ? 1 : initialValues?.minAge);
      const [maxAge, setMaxAge] = useState(!initialValues?.maxAge ? 2 : initialValues?.maxAge);
      const { updateListingError, showListingsError } = fetchErrors || {};
      const saveExit = (
        <Button
          type="button"
          className={css.exitButton}
          inProgress={backbtnLoader}
          onClick={() => {
            saveAndExit(values);
          }}
          disabled={
            submitDisabled ||
            parseInt(maxAge) === 0 ||
            parseInt(minAge) === 0 ||
            parseInt(minAge) > parseInt(maxAge) ||
            parseInt(minAge) === parseInt(maxAge)
          }
        >
          Save & Exit
        </Button>
      );
      const lastTab = publicData?.activityMode === 'online' ? ACTIVITYMODE : LOCATION;
      const errorMessage = updateListingError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingAgeForm.updateFailed" />
        </p>
      ) : null;
      const errorMessageShowListing = showListingsError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingAgeForm.showListingFailed" />
        </p>
      ) : null;
      const customSubmitDisabled =
        values.allowedAge === 'fixedAge'
          ? fixedMinAgeError
          : values.allowedAge === 'ageGroup'
          ? maxAgeError || minAgeError
          : values.allowedAge === 'noRestriction'
          ? false
          : true;

      const minMaxAge = (
        <>
          <div className={css.agecon}>
            <div className={css.minAgeContainer}>
              <label>Min Age:</label>
              <div className={css.butcon}>
                <button
                  type="button"
                  onClick={() => {
                    if (minAge > 0) {
                      setMinAge(state => parseInt(state) - 1);
                      form.change('minAge', parseInt(values.minAge) - 1);
                    }
                  }}
                  disabled={minAge === 0}
                  className={css.ageButton}
                >
                  -
                </button>
                {console.log(456789, minAge, values.minAge)}
                <span className={css.agefigure}>{minAge}</span>
                <button
                  type="button"
                  onClick={() => {
                    if (minAge < 18) {
                      minAge < 18 ? setMinAge(state => parseInt(state) + 1) : null;
                      form.change('minAge', parseInt(values.minAge || 0) + 1);
                    }
                  }}
                  className={css.ageButton}
                  disabled={minAge === 18}
                >
                  +
                </button>
              </div>
            </div>
            <div className={css.maxAgeContainer}>
              <label>Max Age:</label>

              <div className={css.butcon}>
                <button
                  type="button"
                  onClick={() => {
                    if (maxAge > 0) {
                      setMaxAge(state => parseInt(state) - 1);
                      form.change('maxAge', parseInt(values.maxAge) - 1);
                    }
                  }}
                  disabled={maxAge === 0}
                  className={css.ageButton}
                >
                  -
                </button>
                <span className={css.agefigure}>{maxAge}</span>
                <button
                  type="button"
                  onClick={() => {
                    if (maxAge < 18) {
                      setMaxAge(state => parseInt(state) + 1);
                      form.change('maxAge', parseInt(values.maxAge || 0) + 1);
                    }
                  }}
                  className={css.ageButton}
                  disabled={maxAge === 18}
                >
                  +
                </button>
              </div>
            </div>
            {publicData.categories?.includes('placesToVisit') ||
            publicData.categories?.includes('events') ? (
              <div className={css.siblingDiscountCheck}>
                <input
                  type="checkbox"
                  id="allAges"
                  value="allAges"
                  onChange={e => form.change('allAges', e.target.checked)}
                  defaultChecked={initialValues?.allAges}
                />{' '}
                <label htmlFor="allAges" className={``}>
                  <div className={css.labelDiv}>All Ages:</div>
                  <span className={css.checkicon}>
                    {values.allAges ? (
                      <GrCheckboxSelected className={css.checkboxIcon} />
                    ) : (
                      <GrCheckbox className={css.checkboxIcon} />
                    )}
                  </span>{' '}
                </label>
              </div>
            ) : null}
          </div>
        </>
      );
      return (
        <Form
          className={classes}
          onSubmit={e => {
            e.preventDefault();
            props.onSubmit({ maxAge, minAge, allAges: values.allAges });
          }}
        >
          <div className={css.svexbtnsec}>{saveExit}</div>

          <div className={css.formseclist}>
            {publicData.categories?.includes('afterSchoolClub') ||
            publicData.categories?.includes('birthdayParty') ||
            publicData.categories?.includes('breakfastClub') ||
            publicData.categories?.includes('classes') ||
            publicData.categories?.includes('schoolTours') ||
            publicData.categories?.includes('inSchoolProgrammes') ||
            publicData.categories?.includes('workshops') ||
            publicData.categories?.includes('club') ||
            publicData.categories?.includes('camps') ? (
              minMaxAge
            ) : publicData.categories?.includes('placesToVisit') ||
              publicData.categories?.includes('events') ? (
              <>{minMaxAge}</>
            ) : null}
          </div>

          <div className={css.formlistbtmsec}>
            <ProgressBar bgcolor="red" progress={tabPercent} height={30} />
            <div className={css.buttonDiv}>
              <Button type="button" className={css.backButton} onClick={() => backButton(lastTab)}>
                Back
              </Button>
              <Button
                className={css.submitButton}
                type="submit"
                inProgress={submitInProgress}
                disabled={
                  submitDisabled ||
                  parseInt(maxAge) === 0 ||
                  parseInt(minAge) > parseInt(maxAge) ||
                  (parseInt(minAge) === parseInt(maxAge) && !values.allAges)
                }
                ready={submitReady}
              >
                Next
              </Button>
            </div>
          </div>
        </Form>
      );
    }}
  />
);

EditListingAgeFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  fetchErrors: null,
  filterConfig: config.custom.filters,
};

EditListingAgeFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  name: string.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  filterConfig: propTypes.filterConfig,
};

const EditListingAgeForm = EditListingAgeFormComponent;

export default EditListingAgeForm;
