import React, { useEffect, useRef, useState } from 'react';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import { array, bool, func, node, object, oneOfType, shape, string } from 'prop-types';
import classNames from 'classnames';
import omit from 'lodash/omit';
import { propTypes, LISTING_STATE_CLOSED, LINE_ITEM_NIGHT, LINE_ITEM_DAY } from '../../util/types';
import { formatMoney } from '../../util/currency';
import { parse, stringify } from '../../util/urlHelpers';
import config from '../../config';
import { ModalInMobile, Button, NamedLink, Modal, ExternalLink } from '../../components';
import { BookingTimeForm } from '../../forms';
import { createInstance } from '../../util/sdkLoader';
import css from './BookingPanel.module.css';
import avimgnew from '../../assets/footerlogo.png';
import moment from 'moment';
import axios from 'axios';
import { apiBaseUrl } from '../../util/api';
import { storableError } from '../../util/errors';
import { BsDashLg } from 'react-icons/bs';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';
import SwitchUserModal from '../SwitchUserModal/SwitchUserModal';
import { findOptionsForSelectFilter } from '../../util/search';
// This defines when ModalInMobile shows content as Modal
const MODAL_BREAKPOINT = 1023;
const TODAY = new Date();

const priceData = (price, intl) => {
  if (price && price.currency === config.currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: `(${price.currency})`,
      priceTitle: `Unsupported currency (${price.currency})`,
    };
  }
  return {};
};

const openBookModal = (isOwnListing, isClosed, history, location) => {
  if (isOwnListing || isClosed) {
    window.scrollTo(0, 0);
  } else {
    const { pathname, search, state } = location;
    const searchString = `?${stringify({ ...parse(search), book: true })}`;

    history.push(`${pathname}${searchString}`, state);
  }
};
const closeBookModal = (history, location) => {
  const { pathname, search, state } = location;
  const searchParams = omit(parse(search), 'book');
  const searchString = `?${stringify(searchParams)}`;
  history.push(`${pathname}${searchString}`, state);
};

const dateFormattingOptions = { month: 'short', day: 'numeric', weekday: 'short' };

const BookingPanel = props => {
  const [modalOpen, setModalOpen] = useState(null);
  const [childOptions, setChildOptions] = useState([]);
  const [enqModalOpen, setEnqModalOpen] = useState(false);
  const [sendEnquiryMessageSuccess, setSendEnquiryMessageSuccess] = useState(false);
  const [redirectModalOpen, setRedirectModalOpen] = useState(false);
  const [enqModalFields, setEnqModalFields] = useState({
    firstName: '',
    lastName: '',
    phnNo: '',
    email: '',
    msg: '',
  });
  const [jwlModalFields, setjwlModalFields] = useState(1);
  const [joiningErr, setJoiningErr] = useState(false);
  const [contactProviderLoading, setContactProviderLoading] = useState(false);
  const [contactProviderError, setContactProviderError] = useState(false);
  const [joining, setJoining] = useState(false);
  const [waitingListModalOpen, setWaitingListModalOpen] = useState(false);
  const [childListLoading, setChildListLoading] = useState(false);
  const [hideCross, setHideCross] = useState(false);
  const [isWaiting, setIsWaiting] = useState(null);
  const [addChildInitialValues, setAddChildInitialValues] = useState({
    addChild: [
      {
        id: new Date().getTime(),
        firstName: '',
        lastName: '',
        dob: '',
        childMedicalNotes: '',
      },
    ],
  });
  const sdkInstance = useRef(createInstance({ clientId: config.sdk.clientId }));
  const createChildOptions = () => {
    currentUser?.attributes.profile.protectedData?.child &&
      setChildOptions(
        currentUser?.attributes.profile.protectedData?.child.map(c => ({
          key: c.id,
          value: c,
          label: `${c.firstName} ${c.lastName}`,
          dob: c.dob,
        }))
      );
  };
  useEffect(() => {
    props.currentUser && createChildOptions();
    setAddChildInitialValues(
      currentUser?.attributes?.profile?.protectedData?.child || [
        {
          id: new Date().getTime(),
          firstName: '',
          lastName: '',
          dob: '',
          childMedicalNotes: '',
        },
      ]
    );
  }, [props.currentUser]);
  useEffect(() => {
    if (props.currentUser && props.currentUser?.id?.uuid) {
      const waiting = !!currentUser?.attributes?.profile?.privateData?.waitingFor?.includes(
        listing?.id.uuid
      );
      setIsWaiting(waiting);
    }
  }, [props.currentUser]);

  const {
    rootClassName,
    className,
    titleClassName,
    listing,
    isOwnListing,
    unitType,
    onSubmit,
    title,
    subTitle,
    onManageDisableScrolling,
    monthlyTimeSlots,
    history,
    location,
    intl,
    currentUser,
    onFetchTransactionLineItems,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    width,
    publicData,
    currentAuthor,
    checkoutLoading,
    onUpdateProfile,
  } = props;
  const authorBusinessName = currentAuthor?.attributes.profile.publicData?.bname;
  const pathname = location.pathname;
  const isProvider = !!currentUser?.attributes?.profile?.publicData?.isProvider;
  const price = listing.attributes.price;
  const currentStock = listing?.currentStock?.attributes?.quantity;
  const { categories } = listing.attributes.publicData;
  const timeZone =
    listing.attributes.availabilityPlan && listing.attributes.availabilityPlan.timezone;
  const earlyDropOff =
    listing.attributes.publicData && listing.attributes.publicData?.earlyDropOff
      ? listing.attributes.publicData?.earlyDropOff
      : null;
  const earlyDropOffTime =
    listing.attributes.publicData && listing.attributes.publicData?.earlyDropOffTime
      ? listing.attributes.publicData?.earlyDropOffTime
      : null;

  const latePickup =
    listing.attributes.publicData && listing.attributes.publicData?.latePickup
      ? listing.attributes.publicData?.latePickup
      : null;
  const latePickupTime =
    listing.attributes.publicData && listing.attributes.publicData?.latePickupTime
      ? listing.attributes.publicData?.latePickupTime
      : null;
  const hasListingState = !!listing.attributes.state;
  const isClosed = hasListingState && listing.attributes.state === LISTING_STATE_CLOSED;
  const showBookingTimeForm = hasListingState && !isClosed;
  const showClosedListingHelpText = listing.id && isClosed;
  const { formattedPrice, priceTitle } = priceData(price, intl);
  const isBook = !!parse(location.search).book;
  const addChild = child => {
    setChildListLoading(true);
    const trueChild = child.filter(c => c.firstName !== '' && c.lastName !== '' && c.dob !== '');
    const childForSubmit = trueChild.map(c => {
      let id;
      if (c.id === '') {
        id = Math.abs(moment().valueOf() - moment(c.dob).valueOf());
      } else {
        id = c.id;
      }
      return {
        ...c,
        id,
      };
    });
    const updatedValues = {
      protectedData: {
        child: childForSubmit,
      },
    };

    return onUpdateProfile(updatedValues)
      .then(res => {
        setChildOptions(
          childForSubmit?.map(c => ({
            key: c.id,
            value: c,
            label: `${c.firstName} ${c.lastName}`,
            dob: c.dob,
          }))
        );
        setChildListLoading(false);
      })
      .catch(e => {
        setChildListLoading(false);
        console.log(e);
      });
  };
  const subTitleText = !!subTitle
    ? subTitle
    : showClosedListingHelpText
    ? intl.formatMessage({ id: 'BookingPanel.subTitleClosedListing' })
    : null;

  const isNightly = unitType === LINE_ITEM_NIGHT;
  const isDaily = unitType === LINE_ITEM_DAY;
  const unitTranslationKey = isNightly
    ? 'BookingPanel.perNight'
    : isDaily
    ? 'BookingPanel.perDay'
    : 'BookingPanel.perUnit';
  const classes = classNames(rootClassName || css.root, className);
  const titleClasses = titleClassName || css.bookingTitle;
  const instantEnquiryMaybe = (
    <button
      type="button"
      className={`${css.contprov} ${css.createListingButton}`}
      onClick={() => {
        if (currentUser?.id) {
          setEnqModalFields({
            firstName: currentUser?.attributes?.profile.firstName
              ? currentUser?.attributes?.profile.firstName
              : '',
            lastName: currentUser?.attributes?.profile.lastName
              ? currentUser?.attributes?.profile.lastName
              : '',
            phnNo: currentUser?.attributes?.profile?.protectedData?.phnNo
              ? currentUser?.attributes?.profile?.protectedData?.phnNo
              : '',
            email: currentUser?.attributes?.email ? currentUser?.attributes?.email : '',
            msg: '',
          });
          setEnqModalOpen(true);
        } else {
          history.push(createResourceLocatorString('LoginPage', routeConfiguration(), {}, {}), {
            from: pathname,
          });
        }
      }}
    >
      Contact Provider
    </button>
  );
  const thinPaddingForExpressBookingMaybe =
    publicData?.bookingType === 'instantBooking' &&
    (currentStock !== null || typeof currentStock !== 'undefined') &&
    currentStock >= 1
      ? { additionalContainerClassName: css.modalContainerForBookingPanel }
      : {};

  const modalInMobileContent = (
    <ModalInMobile
      // containerClassName={classNames(null, { [css.hideCross]: hideCross })}
      id="BookingTimeFormInModal"
      isModalOpenOnMobile={isBook}
      onClose={() => closeBookModal(history, location)}
      showAsModalMaxWidth={MODAL_BREAKPOINT}
      onManageDisableScrolling={onManageDisableScrolling}
      usePortal
      showLogo={false}
      {...thinPaddingForExpressBookingMaybe}
    >
      {publicData?.bookingType === 'offPlatform' ? (
        <div className={css.offplt}>
          <span className={css.cntspn}>How to Book</span>
          <div>
            <div className={css.bdvs}>
              <p>Off-Platform Booking:</p>
              <span className={css.bokmsg}>
                Visit the activity provider's website for availability and online booking
              </span>
              <button
                type="button"
                className={`${css.contprov} ${css.createListingButton}`}
                onClick={() =>
                  currentUser?.id
                    ? setRedirectModalOpen(true)
                    : history.push(
                        createResourceLocatorString('LoginPage', routeConfiguration(), {}, {}),
                        { from: pathname }
                      )
                }
              >
                Visit Provider's Website
              </button>
            </div>
            <div className={css.ordv}>
              <span></span>
              <p>OR</p>
              <span></span>
            </div>
            <div className={css.bdvs}>
              <p>Contact to Book:</p>
              <span className={css.bokmsg}>Email activity provider directly</span>
              {instantEnquiryMaybe}
            </div>
          </div>
        </div>
      ) : publicData?.bookingType === 'instantBooking' ? (
        currentStock !== null || typeof currentStock !== 'undefined' ? (
          currentStock >= 1 ? (
            <div className={css.formDiv}>
              {/* {width >= 1024 ? null : <img className={css.avimg} src={avimgnew} />} */}
              <div className={css.bookdet}>
                <h4>Express Booking</h4>
                <h6>How many children will be attending?</h6>
              </div>
              {showBookingTimeForm ? (
                <BookingTimeForm
                  checkoutLoading={checkoutLoading}
                  currentUser={currentUser}
                  className={css.bookingForm}
                  formId="BookingPanel"
                  submitButtonWrapperClassName={css.submitButtonWrapper}
                  unitType={unitType}
                  onSubmit={onSubmit}
                  setHideCross={setHideCross}
                  price={price}
                  listingId={listing.id}
                  listing={listing}
                  isOwnListing={isOwnListing}
                  monthlyTimeSlots={monthlyTimeSlots}
                  startDatePlaceholder={intl.formatDate(TODAY, dateFormattingOptions)}
                  endDatePlaceholder={intl.formatDate(TODAY, dateFormattingOptions)}
                  timeZone={timeZone}
                  onFetchTransactionLineItems={onFetchTransactionLineItems}
                  lineItems={lineItems}
                  fetchLineItemsInProgress={fetchLineItemsInProgress}
                  fetchLineItemsError={fetchLineItemsError}
                  addChild={addChild}
                  childOptions={childOptions}
                  childListLoading={childListLoading}
                  earlyDropOff={earlyDropOff}
                  latePickup={latePickup}
                  categories={categories}
                  earlyDropOffTime={earlyDropOffTime}
                  latePickupTime={latePickupTime}
                  width={width}
                  pathname={pathname}
                  history={history}
                  initialValues={{
                    payingOptions: 'fullPayment',
                    emgContact: [],
                    child: [],
                    earlyDropOff: [],
                    latePickup: [],
                    ...addChildInitialValues,
                  }}
                  sdkInstance={sdkInstance.current}
                  isProvider={isProvider}
                  setModalOpen={setModalOpen}
                  onManageDisableScrolling={onManageDisableScrolling}
                />
              ) : null}
            </div>
          ) : publicData?.waitingList === true ? (
            <div className={css.offplt}>
              <span className={css.cntspn}>How to book</span>
              <div className={css.bdvs}>
                <p>+ Waiting List:</p>
                <span className={css.bokmsg}>
                  Although this activity is full, the provider is offering a waiting list option
                </span>
                <button
                  type="button"
                  className={`${css.contprov} ${css.createListingButton}`}
                  onClick={() => {
                    if (currentUser?.id) {
                      setWaitingListModalOpen(true);
                    } else {
                      history.push(
                        createResourceLocatorString('LoginPage', routeConfiguration(), {}, {}),
                        { from: pathname }
                      );
                    }
                  }}
                >
                  + Join Waiting List
                </button>
              </div>
              <div className={css.ordv}>
                <span></span>
                <p>OR</p>
                <span></span>
              </div>
              <div className={css.bdvs}>
                <p>Contact provider:</p>
                <span className={css.bokmsg}>Email activity provider directly</span>
                {instantEnquiryMaybe}
              </div>
            </div>
          ) : (
            <div className={css.offplt}>
              <span className={css.cntspn}>How to book</span>
              <div className={`${css.iebdvs} ${css.bdvs}`}>
                <p>Contact to Book:</p>
                <span className={css.bokmsg}>Email activity provider directly</span>
                {instantEnquiryMaybe}
              </div>
            </div>
          )
        ) : (
          <div className={css.offplt}>
            <span className={css.cntspn}>Get in touch...</span>
            <p>Unable to get booking details</p>
          </div>
        )
      ) : (
        <div className={css.offplt}>
          <span className={css.cntspn}>How to book</span>
          <div className={`${css.iebdvs} ${css.bdvs}`}>
            <p>Contact to Book:</p>
            <span className={css.bokmsg}>Email activity provider directly</span>
            {instantEnquiryMaybe}
          </div>
        </div>
      )}
    </ModalInMobile>
  );
  const timeDurationDays = moment(publicData?.endDate).diff(publicData?.startDate, 'days');
  const timeDurationDaysDisp = timeDurationDays ? ` ${timeDurationDays} days` : null;
  const noOfClassesDisplay =
    categories[0] === 'classes' && publicData?.noOfClasses
      ? ` (${publicData?.noOfClasses} ${publicData?.noOfClasses > 1 ? 'classes' : 'class'})`
      : null;
  const noOfCampsDisplay =
    categories[0] === 'camps' && publicData?.noOfDays
      ? ` (${publicData?.noOfDays} ${publicData?.noOfDays > 1 ? 'days' : 'day'})`
      : null;
  const priceTypeTexts =
    publicData?.priceType === 'perHour'
      ? ' per hour'
      : publicData?.priceType === 'perChild'
      ? ' per child'
      : publicData?.priceType === 'totalPrice'
      ? ' total price'
      : publicData?.priceType === 'perMorning'
      ? ' per morning'
      : publicData?.priceType === 'perAfternoon'
      ? ' per afternoon'
      : publicData?.priceType === 'perDay'
      ? ' per day'
      : publicData?.priceType === 'perTerm'
      ? ' per term'
      : publicData?.priceType === 'perClass'
      ? ' per class'
      : publicData?.priceType === 'perYear'
      ? ' per year'
      : publicData?.priceType === 'perMonth'
      ? ' per month'
      : publicData?.priceType === 'perAdult'
      ? ' per adult'
      : '';
  const formattedCounty = findOptionsForSelectFilter('county', config.custom.filters)?.find(
    f => f.value === publicData?.county
  )?.label;

  const openBookingFormContent = !isBook && (
    <div className={css.openBookingForm}>
      <div className={css.priceContainer}>
        <div className={css.priceValue} title={priceTitle}>
          {formattedPrice}
          {priceTypeTexts}
          {categories[0] === 'classes' ||
          categories[0] === 'workshops' ||
          categories[0] === 'camps' ? (
            <span>
              {categories[0] === 'classes'
                ? noOfClassesDisplay
                : categories[0] === 'camps'
                ? noOfCampsDisplay
                : ' (1 day)'}
            </span>
          ) : null}
        </div>
        <div className={css.perUnit}>
          {publicData?.city && publicData?.county
            ? `${publicData?.city}, ${formattedCounty}`
            : formattedCounty}
        </div>
      </div>
      {showBookingTimeForm ? (
        <Button
          rootClassName={css.bookButton}
          onClick={() =>
            isProvider
              ? setModalOpen(true)
              : openBookModal(isOwnListing, isClosed, history, location)
          }
        >
          Proceed to book
        </Button>
      ) : isClosed ? (
        <div className={css.closedListingButton}>
          <FormattedMessage id="BookingPanel.closedListingButtonText" />
        </div>
      ) : null}
    </div>
  );
  const enquiryModalMaybe = (
    <Modal
      id="contactProviderModal"
      isOpen={enqModalOpen}
      // isOpen={true}
      onClose={() => setEnqModalOpen(false)}
      onManageDisableScrolling={onManageDisableScrolling}
      usePortal={width >= 768 ? true : false}
    >
      <div className={css.modalWidth}>
        <div className={css.formdiv}>
          <p>To: {authorBusinessName}</p>
          <div className={css.rowed}>
            <div>
              <label>
                First Name <span>*</span>
              </label>
              <input
                type="text"
                value={enqModalFields.firstName}
                placeholder="First Name"
                onChange={e => {
                  const value = e.target.value;
                  setEnqModalFields({ ...enqModalFields, firstName: value });
                }}
              />
            </div>
            <div>
              <label>
                Last Name <span>*</span>
              </label>
              <input
                type="text"
                value={enqModalFields.lastName}
                placeholder="Last Name"
                onChange={e => {
                  const value = e.target.value;
                  setEnqModalFields({ ...enqModalFields, lastName: value });
                }}
              />
            </div>
          </div>
          {/* <div className={css.rowed}>
            <div>
              <label>
                Email <span>*</span>
              </label>
              <input
                type="text"
                value={enqModalFields.email}
                placeholder="name@example.com"
                onChange={e => {
                  const value = e.target.value;
                  setEnqModalFields({ ...enqModalFields, email: value });
                }}
              />
            </div>
            <div>
              <label>
                Phone <span>*</span>
              </label>
              <input
                type="text"
                value={enqModalFields.phnNo}
                onChange={e => {
                  const value = e.target.value;
                  setEnqModalFields({ ...enqModalFields, phnNo: value });
                }}
              />
            </div>
          </div> */}
          <div className={css.emaildv}>
            <label>
              Email <span>*</span>
            </label>
            <input
              type="text"
              value={enqModalFields.email}
              placeholder="name@example.com"
              onChange={e => {
                const value = e.target.value;
                setEnqModalFields({ ...enqModalFields, email: value });
              }}
            />
          </div>
          <div>
            <label>
              Message <span>*</span>
            </label>
            <textarea
              value={enqModalFields.msg}
              lang="en-IE"
              spellcheck="true"
              type="text"
              placeholder="Hi! I am interested in learning more about your kids activities"
              onChange={e => {
                const value = e.target.value;
                setEnqModalFields({ ...enqModalFields, msg: value });
              }}
            ></textarea>
          </div>
          <button
            type="button"
            id="SendEnquiry"
            className={classNames(css.enqBtn, { [css.isSuccessBtn]: sendEnquiryMessageSuccess })}
            onClick={() => {
              if (!currentUser && !listing) {
                return;
              }
              setContactProviderLoading(true);
              const params = {
                title: listing.attributes.title,
                userId: currentAuthor?.id?.uuid,
                customerData: { ...enqModalFields, id: currentUser?.id?.uuid },
              };
              axios
                .post(`${apiBaseUrl()}/api/contactProvider`, params)
                .then(resp => {
                  setContactProviderLoading(false);
                  setSendEnquiryMessageSuccess(true);
                  // setTimeout(() => setEnqModalOpen(false), 1000);
                })
                .catch(err => {
                  setContactProviderError(true);
                });
            }}
            disabled={
              enqModalFields.firstName === '' ||
              enqModalFields.phnNo === '' ||
              enqModalFields.lastName === '' ||
              enqModalFields.email === '' ||
              enqModalFields.msg === '' ||
              sendEnquiryMessageSuccess
            }
          >
            {contactProviderLoading
              ? 'Sending Message...'
              : sendEnquiryMessageSuccess
              ? 'Request Sent'
              : 'Contact Provider'}
          </button>
          <p className={css.contactProviderInfo}>
            Your information is only shared with the provider for the purposes of responding to your
            enquiry
          </p>
          <span className={css.err}>{contactProviderError && 'Unable to connect to provider'}</span>
        </div>
      </div>
    </Modal>
  );
  const waitingListModal = (
    <Modal
      id="waitingList"
      isOpen={waitingListModalOpen}
      // isOpen={true}
      onClose={() => setWaitingListModalOpen(false)}
      onManageDisableScrolling={onManageDisableScrolling}
      usePortal={width >= 768 ? true : false}
    >
      <div className={css.jwlModal}>
        <div className={css.jwlform}>
          <p>Join wait list</p>
          <div className={css.jwlmsg}>
            <p>Hi there,</p>
            <p>
              I am interested in
              <input
                type="number"
                className={css.jwlseats}
                value={jwlModalFields}
                onChange={e => {
                  const value = e.target.value;
                  value >= 0 ? setjwlModalFields(value) : null;
                }}
              />
              space in your activity:
            </p>
            <span style={{ 'font-weight': '700' }}>
              <span className={css.titlep}>{listing?.attributes?.title}</span>
              <BsDashLg style={{ color: '#c93b54' }} />
              <span className={css.listingatts}>
                <span>
                  {listing?.attributes?.publicData?.county
                    ? listing?.attributes?.publicData?.county.toUpperCase()
                    : 'ONLINE'}
                </span>
                <BsDashLg />
                <span>{moment(listing?.attributes?.publicData?.startDate).format('MMMM Do')}</span>
                <BsDashLg />
                <span>
                  {listing?.attributes?.publicData?.startTime?.hour < 10
                    ? `0${listing?.attributes?.publicData?.startTime?.hour}`
                    : listing?.attributes?.publicData?.startTime?.hour}
                  .
                  {listing?.attributes?.publicData?.startTime?.minute < 10
                    ? `0${listing?.attributes?.publicData?.startTime?.minute}`
                    : listing?.attributes?.publicData?.startTime?.minute}{' '}
                  –{' '}
                  {listing?.attributes?.publicData?.endTime?.hour < 10
                    ? `0${listing?.attributes?.publicData?.endTime?.hour}`
                    : listing?.attributes?.publicData?.endTime?.hour}
                  .
                  {listing?.attributes?.publicData?.endTime?.minute < 10
                    ? `0${listing?.attributes?.publicData?.endTime?.minute}`
                    : listing?.attributes?.publicData?.endTime?.minute}
                </span>
              </span>
            </span>

            <div className={css.jwluserdet}>
              <p>Please get in touch if availability opens up...</p>
              <div>
                <label htmlFor="jwlEmail">Email: </label>

                <span>{currentUser?.attributes?.email}</span>
              </div>
              <div>
                <label htmlFor="jwlPhnNo">Tel:</label>

                <span>
                  {currentUser?.attributes?.profile.publicData?.isProvider
                    ? currentUser?.attributes?.profile.publicData?.phoneNumber
                    : currentUser?.attributes?.profile.protectedData?.phnNo ||
                      'Please add you phone number in your profile'}
                </span>
              </div>
            </div>

            <p className={css.tnxyp}>
              <p>Thank You,</p>
              {currentUser?.attributes?.profile?.firstName}{' '}
              {currentUser?.attributes?.profile?.lastName}
            </p>
          </div>
          <button
            type="button"
            className={classNames(css.createListingButton, { [css.isSuccessBtn]: isWaiting })}
            onClick={() => {
              if (!isWaiting) {
                setJoining(true);
                const params = {
                  listingId: listing?.id?.uuid,
                  customer: currentUser,
                  authorId: currentAuthor?.id?.uuid,
                  waitingListData: {
                    id: currentUser?.id?.uuid,
                    email: currentUser?.attributes?.email,
                    parentName:
                      currentUser?.attributes?.profile.firstName +
                      ' ' +
                      currentUser?.attributes?.profile.lastName,
                    date: new Date().toString(),
                    phnNo: currentUser?.attributes?.profile.publicData?.isProvider
                      ? currentUser?.attributes?.profile.publicData?.phoneNumber
                      : currentUser?.attributes?.profile.protectedData?.phnNo || '',
                    seats: jwlModalFields || 1,
                  },
                };
                axios
                  .post(`${apiBaseUrl()}/api/joinWaitingList`, params)
                  .then(resp => {
                    setJoining(false);
                    // setWaitingListModalOpen(false);
                    setIsWaiting(true);
                  })
                  .catch(err => setJoiningErr(true));
              }
            }}
            disabled={isWaiting || jwlModalFields <= 0}
          >
            {isWaiting ? 'Request sent' : joining ? 'Submitting...' : 'Submit'}
          </button>
          <span className={css.err}>{joiningErr && 'Unable to connect to provider'}</span>
        </div>
      </div>
    </Modal>
  );
  const redirectToProviderModal = (
    <Modal
      id="redirectModal"
      // isOpen={true}
      isOpen={redirectModalOpen}
      onClose={() => setRedirectModalOpen(false)}
      onManageDisableScrolling={onManageDisableScrolling}
      usePortal={width >= 768 ? true : false}
    >
      <div className={css.redtomodal}>
        <p>Taking you to {authorBusinessName}</p>
        <p>
          You are now leaving Activity Time and going to the activity provider’s chosen website.
        </p>
        <div>
          <ExternalLink
            href={publicData?.referalLink}
            className={`${css.createListingButton} ${css.cntctpr} ${css.extlnk}`}
          >
            Continue
          </ExternalLink>
        </div>
        <span>Please remember to check the provider’s T&C’s and Privacy Policy before booking</span>
      </div>
    </Modal>
  );
  const switchUserModal = (
    <SwitchUserModal modalOpen={modalOpen} isProvider={isProvider} setModalOpen={setModalOpen} />
  );
  return width >= 1024 ? (
    <div className={classes}>
      <div className={css.BookingPanelmodified}>
        {modalInMobileContent}
        {openBookingFormContent}
        {enquiryModalMaybe}
        {redirectToProviderModal}
        {waitingListModal}
        {switchUserModal}
      </div>
    </div>
  ) : (
    <div className={classes}>
      {modalInMobileContent}
      {openBookingFormContent}
      {enquiryModalMaybe}
      {redirectToProviderModal}
      {waitingListModal}
      {switchUserModal}
    </div>
  );
};

BookingPanel.defaultProps = {
  rootClassName: null,
  className: null,
  titleClassName: null,
  isOwnListing: false,
  subTitle: null,
  unitType: config.bookingUnitType,
  monthlyTimeSlots: null,
  lineItems: null,
  fetchLineItemsError: null,
};

BookingPanel.propTypes = {
  rootClassName: string,
  className: string,
  titleClassName: string,
  listing: oneOfType([propTypes.listing, propTypes.ownListing]),
  isOwnListing: bool,
  unitType: propTypes.bookingUnitType,
  onSubmit: func.isRequired,
  title: oneOfType([node, string]).isRequired,
  subTitle: oneOfType([node, string]),
  authorDisplayName: oneOfType([node, string]).isRequired,
  onManageDisableScrolling: func.isRequired,
  monthlyTimeSlots: object,
  onFetchTransactionLineItems: func.isRequired,
  lineItems: array,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

export default compose(withRouter, injectIntl)(BookingPanel);
