import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import * as validators from '../../util/validators';
import { Form, PrimaryButton, FieldTextInput } from '../../components';
import Select, { components } from 'react-select';
import css from './SignupForm.module.css';
import axios from 'axios';
import HCaptcha from '@hcaptcha/react-hcaptcha';
import { useState, useEffect, useRef } from 'react';
import data from './CountyAndTown';
import PhoneInput from 'react-phone-input-2';
import { findOptionsForSelectFilter } from '../../util/search';
import config from '../../config';
const KEY_CODE_ENTER = 13;

const SignupFormComponent = props => {
  const [hcaptchaToken, setHcaptchaToken] = useState(null);
  const [county, setCounty] = useState('');
  const [isClient, setIsClient] = useState(false);
  const [phnErr, setPhnErr] = useState(false);
  const [linkError, setLinkError] = useState(false);
  const countyOption = findOptionsForSelectFilter('county', config.custom.filters);
  const captchaRef = useRef(null);
  const onExpire = () => {
    console.log('hCaptcha Token Expired');
  };

  const onError = err => {
    console.log(`hCaptcha Error: ${err}`);
  };
  useEffect(() => {
    setIsClient(true);
  }, []);
  return (
    <FinalForm
      {...props}
      render={fieldRenderProps => {
        const {
          rootClassName,
          className,
          formId,
          handleSubmit,
          inProgress,
          invalid,
          intl,
          tab,
          form,
          values,
          onOpenTermsOfService,
          history,
          locationFrom,
          tncClassname,
        } = fieldRenderProps;
        // email
        const emailLabel = intl.formatMessage({
          id: 'SignupForm.emailLabel',
        });
        const emailPlaceholder = intl.formatMessage({
          id: 'SignupForm.emailPlaceholder',
        });
        const emailRequiredMessage = intl.formatMessage({
          id: 'SignupForm.emailRequired',
        });
        const emailRequired = validators.required(emailRequiredMessage);
        const emailInvalidMessage = intl.formatMessage({
          id: 'SignupForm.emailInvalid',
        });
        const emailValid = validators.emailFormatValid(emailInvalidMessage);

        // password
        const passwordLabel = intl.formatMessage({
          id: 'SignupForm.passwordLabel',
        });
        const passwordPlaceholder = intl.formatMessage({
          id: 'SignupForm.passwordPlaceholder',
        });
        const passwordRequiredMessage = intl.formatMessage({
          id: 'SignupForm.passwordRequired',
        });
        const passwordMinLengthMessage = intl.formatMessage(
          {
            id: 'SignupForm.passwordTooShort',
          },
          {
            minLength: validators.PASSWORD_MIN_LENGTH,
          }
        );
        const passwordMaxLengthMessage = intl.formatMessage(
          {
            id: 'SignupForm.passwordTooLong',
          },
          {
            maxLength: validators.PASSWORD_MAX_LENGTH,
          }
        );
        const passwordMinLength = validators.minLength(
          passwordMinLengthMessage,
          validators.PASSWORD_MIN_LENGTH
        );
        const passwordMaxLength = validators.maxLength(
          passwordMaxLengthMessage,
          validators.PASSWORD_MAX_LENGTH
        );
        const passwordRequired = validators.requiredStringNoTrim(passwordRequiredMessage);
        const passwordValidators = validators.composeValidators(
          passwordRequired,
          passwordMinLength,
          passwordMaxLength
        );

        // Website
        const webLabel = intl.formatMessage({
          id: 'SignupForm.webLabel',
        });
        const webPlaceholder = intl.formatMessage({
          id: 'SignupForm.webPlaceholder',
        });
        const webRequiredMessage = intl.formatMessage({
          id: 'SignupForm.webRequired',
        });
        const webRequired = validators.required(webRequiredMessage);
        const webInvalidMessage = intl.formatMessage({
          id: 'SignupForm.webInvalid',
        });
        const webValid = validators.validBusinessURL(webInvalidMessage);

        //businessName
        const businessNameLabel = intl.formatMessage({
          id: 'SignupForm.businessNameLabel',
        });
        const businessNamePlaceholder = intl.formatMessage({
          id: 'SignupForm.businessNamePlaceholder',
        });
        const BusinessNameRequiredMessage = intl.formatMessage({
          id: 'SignupForm.businessNameRequired',
        });
        const businessNameRequired = validators.required(BusinessNameRequiredMessage);

        // firstName
        const firstNameLabel = intl.formatMessage({
          id: 'SignupForm.firstNameLabel',
        });
        const firstNamePlaceholder = intl.formatMessage({
          id: 'SignupForm.firstNamePlaceholder',
        });
        const firstNameRequiredMessage = intl.formatMessage({
          id: 'SignupForm.firstNameRequired',
        });
        const firstNameRequired = validators.required(firstNameRequiredMessage);

        // lastName
        const lastNameLabel = intl.formatMessage({
          id: 'SignupForm.lastNameLabel',
        });
        const lastNamePlaceholder = intl.formatMessage({
          id: 'SignupForm.lastNamePlaceholder',
        });
        const lastNameRequiredMessage = intl.formatMessage({
          id: 'SignupForm.lastNameRequired',
        });
        const lastNameRequired = validators.required(lastNameRequiredMessage);

        //county

        const countyRequiredMessage = intl.formatMessage({
          id: 'SignupForm.countyRequired',
        });
        const countyRequired = validators.required(countyRequiredMessage);
        const classes = classNames(rootClassName || css.root, className);
        const submitInProgress = inProgress;
        const submitDisabled = invalid || submitInProgress;

        const handleTermsKeyUp = e => {
          // Allow click action with keyboard like with normal links
          if (e.keyCode === KEY_CODE_ENTER) {
            onOpenTermsOfService();
          }
        };
        const validURL = str => {
          let regex = /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g;
          if (!regex.test(str)) {
            return false;
          } else {
            return true;
          }
        };
        const termsLink = (
          <span
            className={css.termsLink}
            onClick={onOpenTermsOfService}
            role="button"
            tabIndex="0"
            onKeyUp={handleTermsKeyUp}
          >
            <FormattedMessage id="SignupForm.termsAndConditionsLinkText" />
          </span>
        );
        const customControl = props => (
          <components.Control {...props} className={css.customControl}>
            {props.children}
          </components.Control>
        );
        const customOption = props => (
          <components.Option {...props} className={css.customOption}>
            {props.children}
          </components.Option>
        );
        const handleVerificationSuccess = token => {
          setHcaptchaToken(token);
        };
        return (
          <Form className={classes} onSubmit={handleSubmit}>
            {tab === 'signup' ? (
              <div>
                <div className={css.name}>
                  <FieldTextInput
                    className={css.firstNameRoot}
                    type="text"
                    id={formId ? `${formId}.fname` : 'fname'}
                    name="fname"
                    // autoComplete="given-name"
                    label={firstNameLabel}
                    placeholder={firstNamePlaceholder}
                    validate={firstNameRequired}
                  />
                  <FieldTextInput
                    className={css.lastNameRoot}
                    type="text"
                    id={formId ? `${formId}.lname` : 'lname'}
                    name="lname"
                    // autoComplete="family-name"
                    label={lastNameLabel}
                    placeholder={lastNamePlaceholder}
                    validate={lastNameRequired}
                  />
                </div>
                <div className={css.phnWithErr}>
                  <label>Phone Number</label>
                  <PhoneInput
                    country={'ie'}
                    value={values.phnNo}
                    buttonClass={css.rphnin}
                    dropdownClass={css.rphnindd}
                    enableLongNumbers={true}
                    onChange={val => {
                      // values.phoneNumber && isPossiblePhoneNumber(values.phoneNumber)
                      //   ? setPhoneErr(false)
                      //   : '';
                      // values.phoneNumber && values.phoneNumber.length > 8 ? setPhnErr(false) : '';
                      form.change('phnNo', `${val}`);
                    }}
                    onBlur={() => {
                      // values.phoneNumber && isPossiblePhoneNumber(values.phoneNumber)
                      values.phnNo && values.phnNo.length > 8 ? setPhnErr(false) : setPhnErr(true);
                    }}
                  />
                  {phnErr ? (
                    <span className={css.phnErrMsg}>
                      <FormattedMessage id="SignupForm.phoneRequired" />
                    </span>
                  ) : (
                    ''
                  )}
                </div>
                <FieldTextInput
                  type="email"
                  id={formId ? `${formId}.email` : 'email'}
                  name="email"
                  autoComplete="email"
                  label={emailLabel}
                  placeholder={emailPlaceholder}
                  validate={validators.composeValidators(emailRequired, emailValid)}
                />

                <FieldTextInput
                  className={css.password}
                  type="password"
                  id={formId ? `${formId}.password` : 'password'}
                  name="password"
                  autoComplete="new-password"
                  label={passwordLabel}
                  placeholder={passwordPlaceholder}
                  validate={passwordValidators}
                />
              </div>
            ) : (
              <div>
                <div className={css.name}>
                  <FieldTextInput
                    className={css.firstNameRoot}
                    type="text"
                    id={formId ? `${formId}.fname` : 'fname'}
                    name="fname"
                    // autoComplete="given-name"
                    label={firstNameLabel}
                    placeholder={firstNamePlaceholder}
                    validate={firstNameRequired}
                  />
                  <FieldTextInput
                    className={css.lastNameRoot}
                    type="text"
                    id={formId ? `${formId}.lname` : 'lname'}
                    name="lname"
                    // autoComplete="family-name"
                    label={lastNameLabel}
                    placeholder={lastNamePlaceholder}
                    validate={lastNameRequired}
                  />
                </div>
                <FieldTextInput
                  type="email"
                  id={formId ? `${formId}.email` : 'email'}
                  name="email"
                  autoComplete="email"
                  label={
                    <span className={css.bnsEmailTitle}>
                      Business Email: <span>used for all orders and enquiries</span>
                    </span>
                  }
                  placeholder={emailPlaceholder}
                  validate={validators.composeValidators(emailRequired, emailValid)}
                />
                <div className={css.phnWithErr}>
                  <label>Phone Number</label>
                  <PhoneInput
                    country={'ie'}
                    value={values.phoneNumber}
                    buttonClass={css.rphnin}
                    dropdownClass={css.rphnindd}
                    enableLongNumbers={true}
                    onChange={val => {
                      // values.phoneNumber && isPossiblePhoneNumber(values.phoneNumber)
                      //   ? setPhoneErr(false)
                      //   : '';
                      // values.phoneNumber && values.phoneNumber.length > 8 ? setPhnErr(false) : '';
                      form.change('phoneNumber', `${val}`);
                    }}
                    onBlur={() => {
                      // values.phoneNumber && isPossiblePhoneNumber(values.phoneNumber)
                      values.phoneNumber && values.phoneNumber.length > 8
                        ? setPhnErr(false)
                        : setPhnErr(true);
                    }}
                  />
                  {phnErr ? (
                    <span className={css.phnErrMsg}>
                      <FormattedMessage id="SignupForm.phoneRequired" />
                    </span>
                  ) : (
                    ''
                  )}
                </div>
                <FieldTextInput
                  className={css.bname}
                  type="text"
                  id={formId ? `${formId}.bname` : 'bname'}
                  name="bname"
                  // autoComplete="given-name"
                  label={businessNameLabel}
                  placeholder={businessNamePlaceholder}
                  validate={businessNameRequired}
                />
                <FieldTextInput
                  // className={css.bname}
                  type="text"
                  id={formId ? `${formId}.web` : 'web'}
                  name="web"
                  autoComplete="web"
                  label={webLabel}
                  placeholder={webPlaceholder}
                  onBlur={e =>
                    e.target.value === '' || validURL(e.target.value)
                      ? setLinkError(false)
                      : setLinkError(true)
                  }
                />
                {linkError ? (
                  <span className={css.linkError}>Please enter valid website</span>
                ) : null}

                <div className={css.county}>
                  <label>{'Headquartered in'}</label>
                  <Select
                    name="county"
                    id={formId ? `${formId}.county` : 'county'}
                    options={countyOption}
                    isClearable={true}
                    onChange={e => {
                      e === null ? setCounty('') : setCounty(e.value);
                      e === null ? null : form.change('county', e.value);
                    }}
                    validate={countyRequired}
                    components={{ Control: customControl, Option: customOption }}
                    isSearchable={false}
                  />
                </div>

                <FieldTextInput
                  className={css.password}
                  type="password"
                  id={formId ? `${formId}.password` : 'password'}
                  name="password"
                  autoComplete="new-password"
                  label={passwordLabel}
                  placeholder={passwordPlaceholder}
                  validate={passwordValidators}
                />
              </div>
            )}
            <br />
            {isClient && (
              <HCaptcha
                sitekey={process.env.REACT_APP_HCAPTCHA_SITE_KEY} // Replace this with your hCaptcha site key
                onVerify={handleVerificationSuccess}
                onError={onError}
                onExpire={onExpire}
                ref={captchaRef}
              />
            )}
            <div className={css.infoBtnDiv}>
              <PrimaryButton
                type="submit"
                inProgress={submitInProgress}
                disabled={submitDisabled || linkError || !hcaptchaToken}
                className={css.infoBtn}
              >
                {tab === 'signup' ? 'Join Activity Time' : 'Get FREE Listings'}
              </PrimaryButton>
              <p className={classNames(css.bycrt, tncClassname)}>
                By creating your account you accept our{' '}
                <span
                  onClick={e =>
                    history.push('/activity-time-terms-and-conditions', { from: locationFrom })
                  }
                >
                  terms and conditions
                </span>
                .
              </p>
            </div>
          </Form>
        );
      }}
    />
  );
};

SignupFormComponent.defaultProps = { inProgress: false };

const { bool, func } = PropTypes;

SignupFormComponent.propTypes = {
  inProgress: bool,

  onOpenTermsOfService: func.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const SignupForm = compose(injectIntl)(SignupFormComponent);
SignupForm.displayName = 'SignupForm';

export default SignupForm;
